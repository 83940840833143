import Header from "./Header";
import React from "react";
import Layout from "../../components/Layout";
import DigitalSurge from "./DigitalSurge";
import WorkWeDo from "./WorkWeDo";
import WeDoBest from "./WeDoBest";
import OurPartners from "./OurPartners";
import StartSomething from "./StartSomething";

const Index = () => {
  return (
    <Layout>
      <Header />
      <DigitalSurge />
      {/* <WorkWeDo /> */}
      <WeDoBest />
      {/* <OurPartners />
      <StartSomething /> */}
    </Layout>
  );
};

export default Index;
