import React, { useState, useCallback, useEffect } from "react";
import { Link } from "gatsby";

import headervid from "../../images/headervid.jpg";
import { TiMediaPlay } from "react-icons/ti";

import * as HeroStyles from "./hero.module.css";

const Header = () => {
  let names = ["Thinking", "Building", "Living"];
  const [newName, setnewName] = useState("");

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * names.length);
    setnewName(names[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);
  return (
    <div style={{ marginTop: "-80px" }} className={HeroStyles.bg}>
      <svg
        className={HeroStyles.heroElement1}
        xmlns="http://www.w3.org/2000/svg"
        width="720.4310302734375"
        height="561.1519775390625"
        viewBox="0 0 720.431 561.152"
      >
        <g
          id="Group_33"
          data-name="Group 33"
          transform="translate(275.459 -84.081) rotate(30)"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M546.619,4265.342a19.575,19.575,0,0,0,.245-3.561c-.032-2.139-.056-4.865-.075-8.053.016-.344.024-.691.04-1.04l-.044.02c-.158-31.309.245-103.952-.154-124.437-.621-31.917-14-57.935-41.288-74.3-54.074-32.422-108.382-64.6-163.863-94.525-59.178-31.913-130.435,11.525-131.064,78.724-.573,60.847-.415,121.714,0,182.562.217,32.335,14.26,58.426,41.873,74.944,53.833,32.22,107.983,63.981,163.017,94.083,50.883,27.827,114.747-1.319,128.6-57.532C549.22,4310.623,546.753,4288.553,546.619,4265.342Zm-67.043,69.984c-.771,5.065-5.2,7.214-9.461,8.758a39.273,39.273,0,0,1-31.867-1.887c-6.634-3.286-12.988-7.112-19.373-10.868-39.4-23.193-79.8-44.7-118.432-69.171-24.007-15.209-36.314-38.288-36.7-66.5-.7-51.2-.38-102.407-.119-153.613a45.657,45.657,0,0,1,8.129-26.272c8.145-11.417,20.5-16.017,27.506-17.965a11.875,11.875,0,0,0-2.906,3.478,14.262,14.262,0,0,0-1.767,8.794c-.055,8.405.015,16.807-.044,25.208.011,6.513.028,16.494.035,23q.053,54.585-.079,109.169a91.983,91.983,0,0,0,10.22,44.11c9.141,17.371,21.113,28.342,35.955,37.748,14.96,9.491,30.605,17.827,45.914,26.72q42.593,24.761,85.26,49.38C475.7,4327.641,480.426,4329.735,479.576,4335.326Zm-84.331-115.167c-25.762-14.65-28.941-23.712-33.084-29.888a94.509,94.509,0,0,1-14.858-39.759l.024-70.106q.047-31.427.087-62.854l2.091.858c33.409,19.353,66.706,38.906,100.253,58.014,33.27,18.945,49.757,47.2,49.282,85.517-.225,18.076,0,86.124-.063,116.467a16.526,16.526,0,0,1-7.646-2.663Q443.483,4247.62,395.245,4220.159Z"
            transform="translate(-210.042 -3850.879)"
            fill="none"
            stroke="#ffffff"
            stroke-width="3"
            class="svg-elem-1"
          ></path>
          <path
            id="Path_2"
            data-name="Path 2"
            d="M542.314,4115.564c-.368-9.98-1.427-25.556-10.229-42.113-7.338-13.81-18.554-25.424-33.459-34.894-7.326-4.649-14.992-8.764-22.516-13.1-44.573-25.732-89.064-51.618-133.757-77.145-33.27-18.995-75.566-16.278-100.727,6.484,11.806,1.858,23.07,4.031,33.143,9.838C334.41,3999,394.435,4032.745,453.5,4068.076a83.151,83.151,0,0,1,28.455,27.228c10.73,17.063,12.648,35.022,12.667,46.955q-.046,33.8-.091,67.6l-.276,71.872c-.131,4.262-.127,8.539-.048,12.826q-.018,3.619-.032,7.248c.064-.151.135-.309.2-.466.822,26.136,3.021,52.58-8.125,78.837,29.186-13.718,47.752-34.575,53.991-64.937,2.171-10.574,2.661-21.226,2.633-32.1h0C542.974,4278.118,544.753,4181.7,542.314,4115.564Z"
            transform="translate(-116.752 -3889.438)"
            fill="none"
            stroke="#ffffff"
            stroke-width="3"
            class="svg-elem-2"
          ></path>
          <path
            id="Path_3"
            data-name="Path 3"
            d="M564.079,4103.861c-.372-9.981-1.432-25.557-10.232-42.119-7.338-13.806-18.555-25.419-33.46-34.884-7.327-4.649-14.993-8.764-22.516-13.109-44.574-25.733-89.064-51.619-133.757-77.14-33.27-19-75.566-16.283-100.727,6.474,11.809,1.864,23.069,4.041,33.144,9.843,59.641,34.374,119.665,68.111,178.729,103.451a83.032,83.032,0,0,1,28.454,27.224c10.73,17.062,12.648,35.021,12.668,46.949q-.047,33.811-.087,67.606l-.281,71.868c-.13,4.267-.126,8.544-.047,12.83q-.018,3.619-.028,7.248c.06-.147.131-.313.2-.465.822,26.135,3.024,52.574-8.121,78.842C537.2,4354.756,555.764,4333.9,562,4303.54c2.175-10.578,2.665-21.235,2.633-32.108h0C564.734,4266.41,566.514,4170,564.079,4103.861Z"
            transform="translate(-52.477 -3923.999)"
            fill="none"
            stroke="#ffffff"
            stroke-width="3"
            class="svg-elem-3"
          ></path>
          <path
            id="Path_4"
            data-name="Path 4"
            d="M236.272,3961.308c9.651-1.044,18.037,2.291,26.145,7.131,7.892,4.718,16.044,8.995,24.082,13.462-3.408,3.5-1.937,7.911-1.957,11.9-.047,11,.095,18.175.036,28.783-.02,4.109-.075,3.933-.17,13.909-.052,5.674-.075,10.323-.087,13.394a28.745,28.745,0,0,0-2-7.2,21.062,21.062,0,0,0-2.139-4.026,21.341,21.341,0,0,0-8.358-7.317c-11.991-6.131-23.555-13.1-35.3-19.721-4.183-.716-4.475-4.134-4.681-7.253-.755-11.412-.451-22.854-.166-34.281C231.772,3966.458,233.694,3963.687,236.272,3961.308Z"
            transform="translate(-147.036 -3814.375)"
            fill="none"
            stroke="#ffffff"
            stroke-width="3"
            class="svg-elem-4"
          ></path>
        </g>
      </svg>
      <div className="main-container flex ">
        <div className="max-w-5xl px-4 m-auto mt-36 py-11">
          <div className="mb-20 text-center flex-col flex items-center">
            <h1
              className={`xl:text-9xl 2xl:text-9xl text-7xl md:text-7xl  font-bold mb-5  text-white`}
            >
              {"\n "}
              <span className={HeroStyles.GradientText}>
                {newName === "" ? "Building" : newName}
              </span>
              <br />
              The <br />
              Future.
            </h1>
            {/* <p className="text-2xl font-semibold text-white max-w-3xl px-7">
              DigitalSurge is a full-service technology guild with the goal of
              helping businesses navigate through the digital age.
            </p> */}

            <Link
              to="/"
              className={`capitalize z-50 text-white  rounded-full flex justify-center items-center border text-base w-60 py-4 mt-9 border-gray-300 bg-transparent hover:bg-primary hover:text-white hover:border-transparent  ${HeroStyles.GlowBtn}`}
            >
             Comming Soon
            </Link>
          </div>
          <div className="max-w-4xl px-6  m-auto">
            <div className="relative px-9 z-50 rounded-header flex   justify-center items-center ">
              {" "}
              <div className=" relative px-2 mx-auto   z-0 py-10"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
