// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--DE1DN";
export var GradientText = "hero-module--GradientText--HRD3B";
export var GlowBtn = "hero-module--GlowBtn--FymdX";
export var heroElement1 = "hero-module--heroElement1--Kv1SE";
export var bg = "hero-module--bg--I-0z4";
export var AnimateBG = "hero-module--AnimateBG--2AeNu";
export var heroElement2 = "hero-module--heroElement2--ru9bP";
export var heroText = "hero-module--heroText--opgZn";
export var distinctWord = "hero-module--distinctWord--YUcwy";
export var heroParagraph = "hero-module--heroParagraph--bOa-P";
export var eclipse1 = "hero-module--eclipse1--8U1AK";
export var rotating = "hero-module--rotating---mhre";
export var eclipse2 = "hero-module--eclipse2--zocuR";
export var oscil1 = "hero-module--oscil1--UQAZG";