import { Link } from "gatsby";
import React from "react";
import logo from "../../images/logo.png";

const Nav = () => {
  return (
    <div className="bg-transparent w-full ">
      <div className="main-container">
        <div className="flex justify-between items-center h-20">
          <div>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="flex gap-x-8 items-center">
            <ul className="hidden md:flex gap-x-8 lg:gap-x-14">
              {NavLinks.map((link) => {
                const { id, text, url } = link;
                return (
                  <li key={id}>
                    <Link className="text-white" to={url}>
                      {text}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <Link to="/" className="nav-btn">
              contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
const NavLinks = [
  // { id: 1, text: "Projects", url: "/projects" },
  // { id: 2, text: "Services", url: "/services" },
  // { id: 3, text: "Careers", url: "/careers" },
  // { id: 4, text: "Blog", url: "/blog" },
];
