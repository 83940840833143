import React from "react";
import Index from "../views/home/Index";

const IndexPage = () => {
  return (
    <>
      <div>
        <Index />
      </div>
    </>
  );
};

export default IndexPage;
