import React from "react";
import card1 from "../../images/best1.svg";
import card2 from "../../images/best2.svg";
import card3 from "../../images/best3.svg";
import card4 from "../../images/best4.svg";
import { BsArrowRightShort } from "react-icons/bs";

import { Link } from "gatsby";
const WeDoBest = () => {
  return (
    <div className="bg-secondary w-full py-12">
      <div className="main-container ">
        <div className="border-b-4 border-gray pb-10">
          <h2 className="title mb-24">
          <strong> A Commitment To Precision<br/> And Caraftsmanship <br/> In Our Process</strong>.
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-9">
            {cards.map((card) => {
              const { id, img, title, desc, btn } = card;
              return (
                <div>
                  <div className="bg-gray px-14 py-7 rounded-3xl">
                    <img className="mb-4 mt-4" src={img} alt="icon " />
                    <h3 className="best-card-title mb-4">{title}</h3>
                    <p className="text-white text-base leading-8 mb-7">
                      {desc}
                    </p>
                    <div className="w-full flex">
                      {/* <Link
                        className="rounded-full border border-white px-4 py-1"
                        to="/"
                      >
                        <span className="text-white text-lg">{btn}</span>
                      </Link> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeDoBest;
const cards = [
  {
    id: 1,
    img: card3,
    title: "Advanced Bot Detection",
    desc: "Our cutting-edge bot detection technology identifies and mitigates automated threats in real-time, safeguarding your website from spam, content scraping, and other malicious activities.",
    btn: <BsArrowRightShort />,
  },
  {
    id: 2,
    img: card1,
    title: "Adaptive Firewall Technology",
desc: `DigitalSurge's adaptive firewall technology automatically adjusts to evolving threats, providing you with a robust security layer that protects your website against unauthorized access, data breaches, and various cyber attacks.`,
    btn: <BsArrowRightShort />,
  },
  {
    id: 3,
    img: card2,
    title: "User-Friendly Dashboard and Reporting",
    desc: "DigitalSurge's intuitive dashboard delivers actionable insights, allowing you to monitor your website's security status and track incidents with ease. Customizable reports provide detailed information on threats and mitigation efforts, empowering you to make informed decisions about your online security.",
    btn: <BsArrowRightShort />,
  },
  {
    id: 4,
    img: card4,
    title: "Real-Time Threat Intelligence",
    desc: "Our solution continuously monitors and analyzes potential threats, providing real-time alerts and insights to help you proactively address vulnerabilities, ensuring the security of your digital assets and customer data.",
    btn: <BsArrowRightShort />,
  },
];
