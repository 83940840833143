import React from "react";
import logo from "../../images/logo.png";
import facebook from "../../images/social/facebook.svg";
import insta from "../../images/social/instagram.svg";
import linkin from "../../images/social/linkedin.svg";
import dribble from "../../images/social/dribble.svg";
import { Link } from "gatsby";
const Footer = () => {
  return (
    <>
      <div className="bg-secondary w-full py-24">
        <div className="main-container">
          <div className="grid grid-cols-1 md:grid-cols-2 sm:gap-x-20 md:gap-x-12 lg:gap-x-64">
            <div>
              <div className="mb-7">
                <img className="mb-7" src={logo} alt="logo" />
                <ul className=" flex flex-col gap-y-6">
                  <li className="footer-li">A Bold Website Team.</li>
                  <li className="footer-li">
                    Digital Surge 2023. All rights reserved.
                  </li>
                  <li className="footer-li">Privacy Policy</li>
                </ul>
              </div>
            </div>
            <div>
              <div className="mb-7">
                {" "}
                <div className=" flex flex-col ">
                  <p className="footer-title text-white mb-2">Office -</p>
                  <p className="footer-li mb-3">
                     94 n3، Rue Oued Ziz,Agdal, Rabat Morocco
                  </p>
                  <p className="footer-title mb-3">Follow -</p>
                  <ul className="flex flex-wrap gap-x-6 gap-y-2">
                    {social.map((link) => {
                      const { id, icon, url } = link;
                      return (
                        <li key={id}>
                          <a href={url} className="text-white">
                            <img src={icon} alt={url} />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-7">
                {" "}
                <ul className="flex flex-col gap-y-6">
                  {NavLinks.map((link) => {
                    const { id, text, url } = link;
                    return (
                      <li key={id}>
                        {/* <Link className="footer-li" to={url}>
                          {text}
                        </Link> */}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
const social = [
  // { id: 1, icon: facebook, url: "facebook.com" },
  // { id: 2, icon: insta, url: "instagram.com" },
  { id: 3, icon: linkin, url: "https://www.linkedin.com/company/digitalsurge137" },
  // { id: 4, icon: dribble, url: "dribble.com" },
];
const NavLinks = [
  // { id: 1, text: "Home", url: "/" },
  // { id: 2, text: "Projects", url: "/projects" },
  // { id: 3, text: "Services", url: "/services" },
  // { id: 4, text: "Career", url: "/career" },
  // { id: 5, text: "contactus", url: "/contactus" },
];
