import React from "react";
import Nav from "./menue/Nav";
import Footer from "./menue/Footer";
import SEO from "./SEO";

const Layout = ({ children }) => {
  return (
    <>
    <SEO/>
      <Nav />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
