import React from "react";
import bline from "../../images/lineb.svg";
import Lottie from "lottie-react";
import Animation1 from "../../images/animation.json";
import * as HeroStyles from "./hero.module.css";
import Eclipse1 from "../../images/eclipse1.png";
import Eclipse2 from "../../images/eclipse2.png";
const DigitalSurge = () => {
  return (
    <div className="bg-secondary py-10 w-full z-50">
      <img className={HeroStyles.eclipse2} src={Eclipse2}></img>
      <img className={HeroStyles.eclipse1} src={Eclipse1}></img>
      <div className="main-container z-50">
        <div className="grid gap-x-8 gap-y-8 md:grid-cols-2 grid-cols-1">
          <div className="z-50">
            <div className="z-50">
              <h2 className="title z-50">
                We are <br /> DigitalSurge
              </h2>
              <img src={bline} alt="line" />
              <Lottie loop={true} animationData={Animation1} />
            </div>
          </div>
          <div>
            <div className="text-white text-xl z-50">
              <p style={{ zIndex: "900" }}>
DigitalSurge, a cutting-edge cybersecurity solution designed to protect your online presence from bad actors, bots, and cyber attacks. Our easy-to-implement, cost-effective, and scalable software safeguards your digital assets 24/7. 
                <br />
With advanced features like bot detection, real-time threat intelligence, and adaptive firewall technology, DigitalSurge is the perfect choice for businesses of all sizes in Morocco. Our experienced team of cybersecurity experts is committed to keeping your website safe, allowing you to focus on growing your business with peace of mind. Don't leave your digital security to chance – join the DigitalSurge family today!               
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalSurge;
